import React, { useEffect }  from "react";
import { useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import { toggleAuthenticationState } from "../login/login.slice";
import {deleteCookie} from "../../helpers/CookieWrapper";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        deleteCookie('token');
        dispatch(toggleAuthenticationState(false));
        navigate("/login");
    }, [dispatch, navigate]);

    return <div>

    </div>
};

export default Logout;
