import React, {} from "react";
import classNames from "classnames";
import styles from "./pageLoader.module.scss";

interface PageLoaderProps {
    text?: string
}

const PageLoader = (props: PageLoaderProps) => {
    const text = props.text ?? "Loading";

    return <div className={classNames(styles.loader_wrapper)}>
        <span className={classNames(styles.loader)} />
        <span className={`mx-3`}>{ text }</span>
    </div>
};

export default PageLoader;
