import Cookies from "universal-cookie";
import Logout from "../screens/logout";

const getToken = async () => {
    const cookies = new Cookies(null, { path: '/' });
    const token = cookies.get('token');

    return await token;
};

export const _get = async (url: string) => {
    let token = await getToken();

    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    return fetch(`${url}`, requestOptions).then(handleResponse);
};

export const _post = async (url: string, body: any) => {
    const token = await getToken();

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${url}`, requestOptions).then(handleResponse);
};

export const _put = async (url: string, body: any) => {
    const token = await getToken();

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${url}`, requestOptions).then(handleResponse);
};

export const _delete = async (url: string, body: any = null) => {
    const token = await getToken().then(token => token);

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${url}`, requestOptions).then(handleResponse);
};

export const _filePost = async (url: string, body: any) => {
    const token = await getToken();

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        },
        body: body
    };

    return fetch(`${url}`, requestOptions).then(handleResponse);
};

export const handleResponse = async (response: any) => {
    if (!response.ok) {
        if (response?.status === 401) {
            return <Logout/>
            //return Promise.reject(response?.data?.message || "");
        }

        let response_text = null;
        await response?.json().then((res: any) => {
            response_text = res?.error || null;
        });

        return Promise.reject(response_text || response?.data?.message || "");
    }

    return response.json().then((json: any) => {
        if(json?.success) {
            return json;
        }

        if(parseInt(json?.code) === 10) {
            return json;
        }

        return Promise.reject( json?.message || json?.description || "");
    });
};
