import React, { useState, useEffect } from "react";
import {PollingListService} from "../../../services/polling";
import Moment from "moment-timezone";
import PollingItem from "../item";
import classNames from "classnames";
import styles from "./list.module.scss";
import PageLoader from "../../loader";
import {Alert} from "reactstrap";

export interface PollItem {
    EventId: number
    Expiry: string
    "Expiry Time": string
    ExpiryTime: string
    IsAnonymous: "1" | "2"
    PollingId: number
    PollingName: string
    StartDate: string
}

const PollingList = () => {
    const [pollingList, setPollingList] = useState<PollItem[]>([]);
    const [pollingListLoading, setPollingListLoading] = useState(true);

    const fetchPollingList = async () => {
        setPollingListLoading(true);
        await PollingListService(`?PollingType=1`).then(res => {
            setPollingList((res?.data || []).filter((poll: PollItem) => {
                return Moment(poll["Expiry Time"], "YYYY-MM-DD HH:mm:ss").isAfter(Moment());
            }).filter((poll: PollItem) => {
                if(!poll.StartDate) {
                    return poll;
                }

                return Moment(poll.StartDate, "YYYY-MM-DD").isBefore(Moment());
            }));
            setPollingListLoading(false);
        }).catch(err => {
            setPollingList([]);
            setPollingListLoading(false);
        });
    };

    useEffect(() => {
        fetchPollingList();
    }, []);

    if(pollingListLoading) {
        return <PageLoader />
    }

    return <section className={classNames(styles.poll__list)}>
        { pollingList.map(poll => {
            return <PollingItem key={poll.PollingId} pollingItem={poll} />
        }) }
        { pollingList.length === 0 && <Alert color={`warning`}>
            No Data Available
        </Alert> }
    </section>
};

export default PollingList;
