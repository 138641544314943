import React, { useState } from "react";
import {Nav, Navbar, NavbarBrand, NavItem, NavLink, NavbarToggler, Collapse, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/logo.png";
import classNames from "classnames";
import styles from "../../screens/home/index.module.scss";
import {Link} from "react-router-dom";
import RootStore from "../../store/store.types";

const Header = () => {
    const user_details = useSelector((state: RootStore) => state.login.user.details);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return <Navbar color="dark" dark expand={`md`}>
        <NavbarBrand href="/">
            <img
                alt="logo"
                src={Logo}
                className={classNames(styles.navbar_img)}
            />
            Lions District 324K
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
            <Nav className="w-100" navbar>
                <NavItem active>
                    <NavLink tag={Link} to="/" active>Home</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar className={classNames(styles.navbar_option_logout)}>
                    <DropdownToggle nav caret>
                        <span className={`text-white`}>Welcome, <strong>{ user_details.name }</strong></span>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem tag={Link} to={`/logout`}>Logout</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Collapse>
    </Navbar>
};

export default Header;
