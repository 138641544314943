import React from "react";
import Login from "../screens/login";
import Logout from "../screens/logout";
import Home from "../screens/home";
import PollingForm from "../components/polling/form";

interface RouteType {
    path: string;
    component: React.ReactElement;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "/",
        component: <Home />,
        name: "Login Screen",
        protected: true,
    },
    {
        path: "/login",
        component: <Login />,
        name: "Login Screen",
        protected: false,
    },
    {
        path: "/poll/:poll_id",
        component: <PollingForm />,
        name: "Polling Form",
        protected: true,
    },
    {
        path: "/logout",
        component: <Logout />,
        name: "Logout",
        protected: true,
    }
];

export default routes;
