import React from "react";
import {Container} from "reactstrap";
import PollingList from "../../components/polling/list";

const Home = () => {
    return <Container>
        <PollingList/>
    </Container>
};

export default Home;
