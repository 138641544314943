import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RootStore from "../store/store.types";

interface Props {
    children: React.ReactNode;
}

const AuthChecker = ({ children }: Props) => {
    const navigate = useNavigate();
    const authenticatedState = useSelector((state: RootStore) => state.login.user.authenticated);

    useEffect(() => {
        if(!authenticatedState) {
            navigate("/login")
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>{children}</>;
};

export default AuthChecker;
