import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { InitialLoginState } from "../../store/store.types";
import {ValidateTokenService} from "../../services/login";

export interface LoginPayload {
    username: string
    otp: string
    type: "1" | "2"
}
export interface ValidateTokenPayload {
    payload: ""
}
export interface ValidateTokenResponse {
    admin: boolean
    code: number
    coverUrl: string
    description: string
    familyId: number
    memberId: number
    name: string
    regId: number
    token: string
    type: "1" | "2"
}
export const initialState: InitialLoginState = {
    login: {
        loading: false,
        error: null
    },
    user: {
        authenticated: false,
        authentication_loading: true,
        authentication_error: "",
        details: {
            admin: false,
            coverUrl: "",
            type: "1",
            name: "",
            familyId: -1,
            memberId: -1,
            regId: -1
        }
    }
};

export const validateToken = createAsyncThunk('user/token/validate', async (payload: ValidateTokenPayload, { rejectWithValue, dispatch }) => {
    return await ValidateTokenService(payload).then(res => {
        return res;
    }).catch(err => {
        const notify = () => toast.error("Invalid username or password");
        notify();
        return rejectWithValue(err.response.data)
    });
});

const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        toggleAuthenticationState: (state: InitialLoginState, action: { payload: boolean }) => {
            state.user.authenticated = action.payload;
            state.user.authentication_loading = false;
        },
        updateUserDetails: (state: InitialLoginState, action: { payload: ValidateTokenResponse }) => {
            state.user.details = {
                ...action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(validateToken.pending.type, (state: InitialLoginState, action: PayloadAction<any>) => {
            state.user.authentication_loading = true;
            state.user.authentication_error = "";
            state.user.authenticated = false;
        })
        builder.addCase(validateToken.rejected.type, (state: InitialLoginState, action: PayloadAction<any>) => {
            state.user.authentication_loading = false;
            state.user.authenticated = false;
            state.user.authentication_error = action.payload;
        })
        builder.addCase(validateToken.fulfilled.type, (state: InitialLoginState, action: PayloadAction<ValidateTokenResponse>) => {
            state.user.authenticated = true;
            state.user.authentication_loading = false;
            state.user.authentication_error = "";
            state.user.details = {
                ...action.payload
            }
        })
    }
});

export default LoginSlice.reducer;
export const { toggleAuthenticationState, updateUserDetails } = LoginSlice.actions;
