import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import {PollItem} from "../list";
import classNames from "classnames";
import styles from "./item.module.scss";
import {colors, returnRandomArrayItem} from "../../../helpers/Constants";

interface PollingItemProps {
    pollingItem: PollItem
}

const PollingItem = (props: PollingItemProps) => {
    const { pollingItem } = props;

    const bgColor = returnRandomArrayItem(colors);
    return <Link to={`/poll/${ pollingItem.PollingId }`} className={classNames(styles.poll__item)} style={{
        background: `${bgColor}80`,
        borderColor: bgColor
    }}>
        <h6 className={classNames(styles.poll__item_title)}>{ pollingItem.PollingName }</h6>
        <div className={classNames(styles.poll__item_sub_title)}>
            <span className={classNames(styles.poll__item_expiry)}>
                {Moment(pollingItem.Expiry, "YYYY-MM-DD").format("MMM DD, YYYY")}
            </span>
            <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
    </Link>
};

export default PollingItem;
