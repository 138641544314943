import React, {useState, useEffect, Fragment} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import {FormGroup, Input, Container, Card, CardBody, Form, Button, Label, Alert, Row, Col} from "reactstrap";
import * as Yup from "yup";
import {ErrorMessage, Field, FieldInputProps, FieldProps, Formik} from "formik";
import {PollingQuestionsService, PollingAnswer, PollingAnswerSubmit, PollingPayload, QuestionInput, PollingQuestionPayload} from "../../../services/polling";
import PollingResults from "../results";
import {useSelector} from "react-redux";
import RootStore from "../../../store/store.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../loader";
import _ from 'lodash';
import {toast} from "react-toastify";

const PollingForm = () => {
    const {poll_id} = useParams();
    const navigate = useNavigate();
    const userDetails = useSelector((state: RootStore) => state.login.user.details);

    const [pollForm, setPollForm] = useState<PollingQuestionPayload[]>([]);
    const [pollFormLoading, setPollFormLoading] = useState(true);

    const [pollAnswers, setPollAnswers] = useState<{ [key: string]: string }>({});
    const [pollAnswered, setPollAnswered] = useState(false);
    const [pollAnswersLoading, setPollAnswersLoading] = useState(true);

    const [pollFormSubmitLoading, setPollFormSubmitLoading] = useState(false);
    const [showResultsModal, setShowResultsModal] = useState(false);

    const fetchQuizDetailsAnswers = async () => {
        setPollAnswersLoading(true);
        await PollingAnswer(`?RequestType=3&PollingId=${poll_id}`).then(res => {
            const answersObj: {
                [key: string]: string
            } = {};
            const data: PollingQuestionPayload[] = (res?.data || []);
            data.forEach(answer => {
                answersObj[answer.QuestionId] = answer.Answer
            });

            setPollAnswers(answersObj);
            setPollAnswered(data.length > 0);
            setPollAnswersLoading(false);
        }).catch(err => {
            const notify = () => toast.error("Unable to fetch filled answers");
            notify();
            setPollAnswersLoading(false);
        });
    };
    const fetchPollingQuestion = async () => {
        setPollFormLoading(true);
        await PollingQuestionsService(`?type=2&PollingId=${poll_id}`).then(res => {
            setPollForm(res?.data || []);
            setPollFormLoading(false);
        }).catch(err => {
            const notify = () => toast.error("Unable to fetch question");
            notify();
            setPollForm([]);
            setPollFormLoading(false);
        });
    };

    useEffect(() => {
        fetchPollingQuestion();
        fetchQuizDetailsAnswers();
    }, []);

    const renderDropDownOptions = (question: QuestionInput) => {
        switch (question.QuestionType) {
            case "2":
                return _.range(1, 6).map((rating: number) => {
                    return <option value={rating} key={`question_${question.QuestionId}_rating_${rating}`}>
                        {rating}
                    </option>
                });
            case "3":
                return <Fragment>
                    <option value={`Yes`}>
                        Yes
                    </option>
                    <option value={`No`}>
                        No
                    </option>
                </Fragment>;
            case "4":
                return question.QuestionTags.map(tag => {
                    return <option value={tag.Answers} key={`question_${question.QuestionId}_tag_${tag.Id}`}>
                        {tag.Answers}
                    </option>
                })
        }
    };
    const renderQuestionInputs = (question: QuestionInput, field: FieldInputProps<any>, index: number) => {
        if (["2", "3", "4"].includes(question.QuestionType)) {
            return <Input key={`question_${question.QuestionId}`} type={"select"} placeholder={`${question.Question}`}
                          disabled={pollAnswered} autoFocus={index === 0} {...field}>
                <option value={``}>
                    Choose an option
                </option>
                {renderDropDownOptions(question)}
            </Input>
        } else {
            return <Input key={`question_${question.QuestionId}`}
                          type={question.QuestionType === "5" ? "number" : "text"} placeholder={`Enter your answer`}
                          disabled={pollAnswered} autoFocus={index === 0} {...field} />
        }
    };
    const formSubmitHandler = async (values: PollingPayload) => {
        setPollFormSubmitLoading(true);
        await PollingAnswerSubmit(values).then(res => {
            const notify = () => toast.success("Answers submitted successfully");
            notify();
            setPollFormSubmitLoading(false);
            navigate("/");
        }).catch(err => {
            const notify = () => toast.error(err || "Unable to save answers. Please try again!");
            notify();
            setPollFormSubmitLoading(false);
        });
    };

    const pollingValidation = Yup.object().shape({
        RequestType: Yup.string().required("Required"),
        FamilyId: Yup.string().required("Required"),
        MemberId: Yup.string().required("Required"),
        PollingId: Yup.string().required("Required"),
        Answers: Yup.array(Yup.object().shape({
            QuestionId: Yup.string().required("Required"),
            Answer: Yup.string().when(["QuestionType", "QuestionRequired"], {
                is: (field1Value: string, field2Value: string) => field1Value === "5" && field2Value === "1",
                then: (schema) => schema.matches(/^[0-9]+$/, "Invalid Number").required("Required")
            }).when(["QuestionType", "QuestionRequired"], {
                is: (field1Value: string, field2Value: string) => field1Value === "5" && field2Value !== "1",
                then: (schema) => schema.matches(/^[0-9]+$/, "Invalid Number")
            }).when(["QuestionType", "QuestionRequired"], {
                is: (field1Value: string, field2Value: string) => field1Value !== "5" && field2Value === "1",
                then: (schema) => schema.required("Required")
            })
        }))
    });

    if (pollFormLoading || pollAnswersLoading) {
        return <Loader/>
    }

    return <Container className={`mt-3 pb-5`}>
        <Card>
            <CardBody>
                <Formik
                    initialValues={{
                        RequestType: "2",
                        FamilyId: userDetails?.familyId || -1,
                        MemberId: userDetails?.memberId || -1,
                        PollingId: poll_id || "",
                        Answers: pollForm.map(question => {
                            return {
                                QuestionId: question.QuestionId,
                                QuestionType: question.Type,
                                QuestionRequired: question.IsRequired,
                                Question: question.Question,
                                QuestionTags: question.Tags,
                                Answer: pollAnswers?.[question.QuestionId] || ""
                            }
                        })
                    }}
                    validationSchema={pollingValidation}
                    onSubmit={formSubmitHandler}
                >
                    {({handleSubmit, values}) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Row className={`align-items-center`}>
                                    <Col md={3}>
                                        <Button tag={Link} color={`Link`} to={`/`}>
                                            <FontAwesomeIcon icon={faChevronLeft}/> Back
                                        </Button>
                                    </Col>
                                    <Col md={6} className={`text-center`}>
                                        <h3 className={`mb-0`}>{pollForm?.[0]?.PollingName || "-"}</h3>
                                    </Col>
                                    { userDetails.admin && <Col md={3} className={`text-center text-md-end mt-3 mt-md-0`}>
                                        <Button type={`button`} color={`primary`} outline size={`sm`} onClick={() => setShowResultsModal(true)}>
                                            Show Results
                                        </Button>
                                    </Col> }
                                </Row>
                                <hr/>
                                {values.Answers.map((question, index) => {
                                    return <Field name={`Answers.${index}.Answer`}
                                                  key={`question_${question.QuestionId}`}>
                                        {({
                                              field,
                                              meta,
                                          }: FieldProps) => (
                                            <FormGroup>
                                                <Label>{index + 1}. {question.Question}</Label>
                                                {renderQuestionInputs(question, field, index)}
                                                {meta.touched && meta.error && (
                                                    <ErrorMessage name={`Answers.${index}.Answer`} component="div">
                                                        {(msg) => (
                                                            <small className={"text-danger"}>
                                                                {msg}
                                                            </small>
                                                        )}
                                                    </ErrorMessage>
                                                )}
                                            </FormGroup>
                                        )}
                                    </Field>
                                })}
                                <hr/>
                                {!pollAnswered &&
                                    <Button type={"submit"} block color={`primary`} disabled={pollAnswered || pollFormSubmitLoading}>
                                        { pollFormSubmitLoading ? "Submitting..." : "Submit" }
                                    </Button>}
                                {pollAnswered && <Alert color={`warning`}>
                                    You have submitted the form!
                                </Alert>}
                            </Form>
                        )
                    }}
                </Formik>
            </CardBody>
        </Card>
        {showResultsModal && <PollingResults pollQuestions={pollForm} onClose={() => setShowResultsModal(false)} />}
    </Container>
};

export default PollingForm;
