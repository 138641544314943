import React, {Fragment, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import routes from "./config/routes";
import PrivateRoutes from "./helpers/PrivateRoutes";
import PageLoader from "./components/loader";
import RootStore from "./store/store.types";
import {getCookie} from "./helpers/CookieWrapper";
import {validateToken, toggleAuthenticationState} from "./screens/login/login.slice";
import Header from "./components/header";

const App = () => {
  const dispatch = useDispatch();

  const authenticatedStateLoading = useSelector((state: RootStore) => state.login.user.authentication_loading);

  useEffect(() => {
    const token = getCookie("token");
    if(token) {
      dispatch(validateToken({
        "payload": ""
      }) as any);
    } else {
      dispatch(toggleAuthenticationState(false));
    }
  }, [dispatch]);

  if(authenticatedStateLoading) {
    return <PageLoader text={`Authenticating`} />
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
              <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                        <PrivateRoutes>
                          <Header />
                          {route.component}
                        </PrivateRoutes>
                    ) : (
                        <Fragment>
                          {route.component}
                        </Fragment>
                    )
                  }
              />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer theme={'colored'}/>
    </div>
  );
}

export default App;
