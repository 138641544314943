import { _get, _post } from "../helpers/FetchWrapper";
import { POLLING, POLLING_QUESTIONS, POLLING_ANSWERS } from "../config/apis";

export interface PollingQuestionPayload {
    IsRequired: "1" | "2"
    PollingId: string
    PollingName: string
    Question: string
    QuestionId: string
    Tags: QuestionTags[]
    Type: string
    Answer: string
}

export interface QuestionInput {
    QuestionId: string;
    QuestionType: string;
    QuestionRequired: string;
    Question: string;
    QuestionTags: QuestionTags[];
    Answer: string;
}

export interface QuestionTags {
    Answers: string
    Id: string
    PollingId: string
    QuestionId: string
}

export interface PollingPayload {
    RequestType: string
    FamilyId: number
    MemberId: number
    PollingId: string
    Answers: {
        QuestionId: string
        QuestionType: string
        QuestionRequired: string
        Question: string
        QuestionTags: QuestionTags[]
        Answer: string
    }[]
}

export const PollingListService = async (params: string) => {
    return await _get(`${ POLLING }${ params }`);
};

export const PollingQuestionsService = async (params: string) => {
    return await _get(`${ POLLING_QUESTIONS }${ params }`);
}

export const PollingAnswer = async (params: string) => {
    return await _get(`${ POLLING_ANSWERS }${ params }`);
};

export const PollingAnswerSubmit = async (payload: PollingPayload) => {
    return await _post(`${ POLLING_ANSWERS }`, payload);
};
