import Cookies from "universal-cookie";

export const getCookie = (key: string) => {
    const cookies = new Cookies(null, { path: '/' });
    return cookies.get('token');
};

export const setCookie = (key: string, value: string, options?: {
    path: string
    expires: Date
}) => {
    const cookies = new Cookies(null, { path: '/' });
    cookies.set(key, value, options || {});
};

export const deleteCookie = (key: string) => {
    const cookies = new Cookies(null, { path: '/' });
    cookies.remove(key);
}
