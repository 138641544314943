import React, {useState, useEffect, Fragment} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import {PollingAnswer, PollingQuestionPayload} from "../../../services/polling";
import {useParams} from "react-router-dom";
import _ from "lodash";
import PageLoader from "../../loader";
import {toast} from "react-toastify";

interface PollingResultsProps {
    pollQuestions: PollingQuestionPayload[]
    onClose: () => void
}
interface PayloadResponse {
    Name: string
    Timestamp: string
    Type: string
    [key: string]: string
}
interface TransformedPollQuestions {
    question: string
    answers: {
        answer: string
        count: number
    }[]
}

const PollingResults = (props: PollingResultsProps) => {
    const {poll_id} = useParams();
    const renderDropDownOptions = (question: PollingQuestionPayload) => {
        switch (question.Type) {
            case "2":
                return _.range(1, 6).map((rating: number) => {
                    return {
                        "answer": rating,
                        "count": 0
                    }
                });
            case "3":
                return [{
                    "answer": "Yes",
                    "count": 0
                }, {
                    "answer": "No",
                    "count": 0
                }];
            case "4":
                return question.Tags.map(tag => {
                    return {
                        "answer": tag.Answers,
                        "count": 0
                    }
                })
        }
    };
    const transformedQuestions = () => {
        const questions = props.pollQuestions;
        return questions.map(question => {
            return {
                question: question.Question,
                answers: renderDropDownOptions(question)
            }
        })
    };

    const [pollQuestions, setPollQuestions] = useState<TransformedPollQuestions[]>(transformedQuestions());
    const [answersLoading, setAnswersLoading] = useState(true);

    const fetchResultsList = async () => {
        setAnswersLoading(true);
        await PollingAnswer(`?RequestType=1&PollingId=${poll_id}`).then(res => {
            if (res?.data) {
                const results: PayloadResponse[] = res?.data;
                const pollQuestionsClone: TransformedPollQuestions[] = _.cloneDeep(pollQuestions);
                const updatedPollQuestion: TransformedPollQuestions[] = pollQuestionsClone.map(question => {
                    results.forEach(result => {
                        for(const key in result) {
                            if(key === question.question) {
                                question.answers.map((answer) => {
                                    if(answer.answer === result[key]) {
                                        answer.count += 1;
                                    }
                                    return answer;
                                });
                            }
                        }
                    });
                    return question;
                });

                setPollQuestions(updatedPollQuestion);
            }
        }).catch(err => {
            const notify = () => toast.error("Unable to fetch answers");
            notify();
        })
        setAnswersLoading(false);
    };

    useEffect(() => {
        fetchResultsList();
    }, []);

    return <Modal isOpen={true} fade={false} size={`lg`} centered>
        <ModalHeader>
            Results for { props.pollQuestions?.[0]?.PollingName || "-" }
        </ModalHeader>
        <ModalBody>
            { answersLoading && <div className={`pt-5 pb-5 mt-5 mb-5`}>
                <PageLoader />
            </div>}
            { !answersLoading && pollQuestions.map((question, index) => {
                return <Fragment><div>
                    <h6>{question.question}</h6>
                    { question.answers.map(answer => {
                        return <div>
                            { answer.answer } - { answer.count }
                        </div>
                    }) }
                    </div>
                    {index !== (pollQuestions.length - 1) && <hr/>}
                </Fragment>
            }) }
        </ModalBody>
        <ModalFooter>
            <Button type={`button`} color={`secondary`} outline onClick={props.onClose} size={`sm`}>
                Close
            </Button>
        </ModalFooter>
    </Modal>
};

export default PollingResults;
