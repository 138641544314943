import {_post, _put} from "../helpers/FetchWrapper";
import {LOGIN, TOKEN_AUTH} from "../config/apis";
import {LoginPayload, ValidateTokenPayload} from "../screens/login/login.slice";

export const LoginService = async (payload: LoginPayload) => {
    return await _post(LOGIN, payload);
};

export const ValidateTokenService = async (payload: ValidateTokenPayload) => {
    return await _put(TOKEN_AUTH, payload)
};
